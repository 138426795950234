<template>
    <div class="system-message">
        <div class="content">
            <div class="icon not-found">
                <img src="/build/icons/not-found.svg">
            </div>
            <div class="title">
                {{ $t('not-found.title') }}
            </div>
            <div class="description">
                {{ $t('not-found.description') }}
            </div>
            <div class="action-button">
                <router-link class="button-tbf-blue" :to="{name: 'dashboard' }">
                    <span class="text">{{$t('not-found.button')}}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>


<script>

    export default {
        data(){
            return{
            }
        },
        mounted(){
        }
    }
</script>
